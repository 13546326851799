html,
body {
  height: 100%;
  background-color: #000;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-shadow: 0 0.025rem 0.01rem rgba(0, 0, 0, 0.5);
  /* font-size: 14px; */
}
b,
strong {
  font-weight: 500 !important;
}
a {
  color: #fff;
  cursor: pointer;
}

.img-thumbnail {
  padding: 0 !important;
  border: 3px solid #dee2e6 !important;
}

.header {
  text-align: center;
}
.highTemp {
  color: #ff0000 !important;
}
.lowTemp {
  color: #9d9df1 !important;
}
.current-conditions {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}
.current-conditions ul {
  white-space: nowrap;
  font-size: 0.8em;
}
.current-conditions::-webkit-scrollbar {
  height: 0px;
}
.hourly-weather ul {
  font-size: 0.8em;
  list-style: disc !important;
}
#refresh {
  cursor: pointer;
  font-size: 0.8em;
}
.verticalCenterAlign {
  align-self: center;
}
.daily-summary {
  font-size: 0.8em;
}
.temperature_range {
  min-width: 150px;
  max-width: 65%;
  margin-top: 0px;
  height: 20px;
  line-height: 20px;
}
.temperature_range .ranger {
  position: relative;
  top: 4px;
  height: 10px;
  min-width: 25px;
  border-radius: 5px;
  background-color: #8cacd4;
}
.temperature_range .ranger .temperature_min {
  left: -48px;
  text-align: right;
  color: #7474ec !important;
}
.temperature_range .ranger .temperature_max {
  right: -48px;
  text-align: left;
  color: #ff0000;
}
.temperature_range .ranger span {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 45px;
  height: 20px;
  line-height: 12px;
  font-size: 16px;
  color: #666;
}
.current-conditions ul li {
  margin-right: 0.1em !important;
}
.even-row {
  background-color: #474848 !important;
}
.noGutter {
  margin-left: -12px;
  margin-right: -12px;
}
.dailyWeatherDetailsAccordionBody {
  background-color: #212529 !important;
}
.accordion-button {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.accordion-button,
.accordion-button:not(.collapsed),
.accordion-button:focus {
  background-color: #212529 !important;
  color: #fff;
  border: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.summary {
  font-size: 0.9em;
  color: floralwhite;
}
.dailyCardImage {
  height: 120px !important;
}
.accordion-item {
  background-color: #000;
}
.noWrap {
  white-space: nowrap;
}
.noArrow::after {
  display: none;
}
.dailyPOP {
  font-size: 0.75em;
  white-space: nowrap;
}
.hourlyPop {
  font-size: 0.75em;
}
.hourlyTime {
  font-size: 0.9em;
}
.hourlyTemp {
  font-size: 1em;
}
.hourlyFeelsLike {
  font-size: 0.65em;
}
img.dailyWeatherImage {
  max-height: 35px;
}

.currentWeatherIcon i {
  font-size: 1.5em;
}
.dailyWeatherIcon i {
  font-size: 1.25em;
}
.hourlyWeatherIcon i {
  font-size: 1.25em;
}
.daily-tiles-container {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}
.daily-tiles-container ul {
  white-space: nowrap;
  font-size: 0.8em;
}
.daily-tiles-container::-webkit-scrollbar {
  height: 0px;
}
.daily-tiles-details {
  width: 100%;
}
.overview-content {
  font-size: 0.8em;
}